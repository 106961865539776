.searchbar_container {
  width: 53.5625rem;
  height: 3.75rem;
  margin: 3.5rem 0;
}

.product_detail_container {
  width: 51.25rem;
}
.bottomSidebar {
  display: none;
}
.btn {
  margin-top: 1.25rem;
  display: flex;
  width: 98%;
  height: 3rem;
  padding: 0.5rem 0.875rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
  border: 0.5px solid #a5a5a5;
  margin-left: auto;
  margin-right: auto;
}
.btn .content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.btn .text {
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(
    --l1,
    linear-gradient(91deg, #a04bff 9.06%, #ff4ba1 58.02%, #fff625 117.1%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn svg {
  width: 1.5rem;
  height: 1.5rem;
  /* transform: rotate(90deg); */
}

@media only screen and (max-width: 1435px) {
  .searchbar_container {
    width: 60%;
    /* height: 3rem; */
    margin: 2.5rem 0;
  }
  .product_detail_container {
    width: 60%;
  }
  .bottomSidebar {
    display: none;
  }
  /* .logo_container {
    padding-left: 3rem;
  } */
}
@media only screen and (max-width: 1024px) {
  .searchbar_container {
    width: 100%;
    height: 3rem;
    margin: 2.5rem 0;
  }
  .product_detail_container {
    width: 100%;
  }
  .bottomSidebar {
    display: block;
  }
  /* .logo_container {
    padding-left: 3rem;
  } */
}

.product_detail_container_skeleton {
  width: 51.25rem;
  height: calc(100vh - 7rem - 60px - 37px - 1.7rem - 1.8rem);
  overflow: hidden;
}

@media only screen and (max-width: 1435px) {
  .product_detail_container_skeleton {
    width: 51.25rem;
    height: calc(100vh - 5rem - 60px - 37px - 1.7rem - 1.8rem);
    overflow: hidden;
  }
}
