.container {
  width: 100%;
  /* height: 5.6875rem; */
  padding: 1.6875rem 0 1.73294rem 6.25rem;

  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
  
}
.bigLogo {
  display: block;
}
.smallLogo {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .container {
    display: flex;
    padding: 1rem 1.5625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .bigLogo {
    display: none;
  }
  .smallLogo {
    display: block;
  }
}
@media only screen and (max-width: 500px) {
  .container {
    display: flex;
    /* width: 23.4375rem; */
    padding: 1rem 1.5625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .bigLogo {
    display: none;
  }
  .smallLogo {
    display: block;
  }
}
