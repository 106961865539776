.container {
  display: flex;
  width: 9.875rem;
  height: 3rem;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
  background: var(--Blue, #4886ff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.btn_container {
  display: flex;
  align-items: flex-start;
  gap: 0.5625rem;
}
/* .btn_container svg {
  width: 1.5rem;
  height: 1.5rem;
} */
.btn_container .text {
  color: var(--white, #fff);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 159.008%; /* 1.59006rem */
}
@media only screen and (max-width: 1024px) {
  .container {
    height: 2.5rem;
  }
}
@media only screen and (max-width: 500px) {
}
