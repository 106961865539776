.container {
  display: flex;
  justify-content: center;
  gap: 1.25rem;
  align-self: stretch;
  flex-wrap: wrap;
  /* width: 53.75rem; */
  /* padding: 0 10rem; */
}
.suggestion_container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 26.25rem;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem;
  border: 0.5px solid #a5a5a5;
  background: #fff;
}
.suggestion_container .suggestion_cont {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
}
.suggestion_cont .img {
  display: flex;
  width: 3.75rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background: #fff;
}
.suggestion_cont img {
  width: 3.9375rem;
  height: 3.875rem;
  flex-shrink: 0;
  border-radius: 0.8rem;
}
.suggestion_cont .suggestion_text {
  width: 16.125rem;
  align-self: stretch;
  color: #656565;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.suggestion_container .suggestion_right_arrow {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}
@media only screen and (max-width: 1023px) {
  .container {
    display: flex;
    width: 34.375rem;
    gap: 1.25rem 1.375rem;
    flex-wrap: wrap;
  }
  .suggestion_container {
    width: 16.5rem;
    /* height: 4rem; */
  }
  .suggestion_container .suggestion_cont {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
  }
  .suggestion_cont .img {
    width: 2.95313rem;
    height: 2.90625rem;
    flex-shrink: 0;
    border-radius: 1rem;
  }
  .suggestion_cont img {
    width: 2.95313rem;
    height: 2.90625rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
  }
  .suggestion_cont .suggestion_text {
    width: 8.25rem;
    flex-shrink: 0;
    color: #656565;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .suggestion_container .suggestion_right_arrow {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }
  .detail_container {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    justify-content: space-between;
  }

  .detail_suggestion_container {
    position: relative;
    width: 48%;
    display: flex;
    gap: 1rem;
    display: flex;
    /* width: 20.0625rem; */
    height: 4rem;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.75rem;
    border: 0.5px solid #a5a5a5;
    background: #fff;
  }

  .detail_suggestion_cont {
    position: relative;
    display: flex;
    width: 12.0625rem;
    align-items: center;
    gap: 1rem;
    flex-shrink: 0;
  }

  .detail_img {
    display: flex;
    width: 2.8125rem;
    height: 2.8125rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.46875rem;
    background: #fff;
  }
  .detail_img img {
    width: 2.95313rem;
    height: 2.90625rem;
    flex-shrink: 0;
  }
  .detail_suggestion_text {
    width: 10rem;
    flex-shrink: 0;
    color: #656565;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .detail_suggestion_right_arrow {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
  }
}
@media only screen and (max-width: 500px) {
  .container,
  .detail_container {
    display: flex;
    width: calc((100vw - 3.125rem));

    /* align-items: flex-start;
    align-content: flex-start; */
    gap: 1rem;
    flex-wrap: wrap;
  }

  .suggestion_container {
    width: 100%;
  }
  .detail_suggestion_container {
    width: 100%;
  }
  .suggestion_container .suggestion_cont {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
  }
  .suggestion_cont .img {
    width: 3.9375rem;
    height: 3.875rem;
    flex-shrink: 0;
  }
  .suggestion_cont img {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }
  .suggestion_cont .suggestion_text {
    font-size: 1rem;
  }
}
