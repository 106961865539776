.seperator_wrapper {
  width: 100%;
}

.seperator_wrapper .seperator {
  width: 100vw;
  max-width: 3000px;
  height: 5px;
  animation: rotate 1s infinite linear;
  -webkit-animation: rotate 1s infinite linear;
}

@-webkit-keyframes rotate {
  from {
    background-position: -100vw;
  }
  to {
    background-position: 0px;
  }
}
@keyframes rotate {
  from {
    background-position: -100vw;
  }
  to {
    background-position: 0px;
  }
}

.gradient {
  background: linear-gradient;
  background: #30ff90;
  /* Old browsers */
  background: -moz-linear-gradient(
    91deg,
    #a04bff 9.06%,
    #ff4ba1 58.02%,
    #fff625 117.1%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    91deg,
    #a04bff 9.06%,
    #ff4ba1 58.02%,
    #fff625 117.1%
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    91deg,
    #a04bff 9.06%,
    #ff4ba1 58.02%,
    #fff625 117.1%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    #30ff90 0%,
    #ed2ded 25%,
    #c99826 50%,
    #30ffe6 75%,
    #30ff90 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    91deg,
    #a04bff 9.06%,
    #ff4ba1 58.02%,
    #fff625 117.1%
  );
  /* IE10+ */
  background: linear-gradient(
    to right,
    #30ff90 0%,
    #ed2ded 25%,
    #c99826 50%,
    #30ffe6 75%,
    #30ff90 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30ff90', endColorstr='#30ff90',GradientType=1 );
  /* IE6-9 */
}
