/* .container {
 
  display: flex;
  justify-content: center;
} */

.input_container {
  position: relative;
  display: flex;
  /* height: 3.75rem;
  width: 53.75rem; */
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.input_container::after {
  content: "";
  position: absolute;
  top: calc(-1 * 3px);
  left: calc(-1 * 3px);
  height: calc(100% + 3px * 2);
  width: calc(100% + 3px * 2);
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  border-radius: 1rem;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
  filter: blur(2px);
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.input_container input {
  /* Styles for input inside .container .input_container */
  position: relative;
  display: flex;
  width: 100%;
  height: 3.75rem;
  padding: 0.9375rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.75rem;
  border: 3px solid var(--l1, #a04bff);
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  padding-left: 3.5rem;
  padding-right: 4rem;
  font-size: 1.5rem;
  border: none;
  outline: none;
}

.input_container .search_icon {
  /* Styles for .search_icon inside .container .input_container */
  position: absolute;
  top: 50%;
  left: 1.25rem;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
}

.animatedP {
  /* Styles for .default_search_btn inside .container */
  position: absolute;
  top: 50%;
  left: 4rem;
  transform: translateY(-50%);
}
.default_search_btn {
  /* Styles for .default_search_btn inside .container */
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
}

.typing_search_btn {
  /* Styles for .typing_search_btn inside .container */
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
  display: flex;
  width: 2.125rem;
  height: 2.125rem;
  padding: 0.375rem 0.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: var(--Blue, #4886ff);
}
.typing_search_btn::before {
  /* Styles for .typing_search_btn inside .container */
  position: absolute;
  content: "";
  width: 1rem;
  height: 2.125rem;
  left: -1rem;
  background: var(--Blue, white);
}

.rest_search_btn {
  /* Styles for .rest_search_btn inside .container */
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 1435px) {
  .input_container::after {
    /* top: -10px;
    height: 0; */
  }
}

@media only screen and (max-width: 1024px) {
  .input_container input {
    padding: 0;
    padding-left: 1rem;
    padding-right: 3rem;
    font-size: 1rem;
  }

  .input_container .search_icon {
    display: none;
  }

  .input_container::after {
    top: calc(-3 * 3px);
    height: calc(3.75rem + 3px * 2);
  }
  .animatedP {
    left: 1.5rem;
  }
}
@media only screen and (max-width: 500px) {
}
