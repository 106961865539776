.c_container {
  display: flex;
  width: 19rem;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--white, #fff);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
}
.c_container img,
.c_container video {
  width: 19.125rem;
  height: 8.9375rem;
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.c_container .text_wrap {
  color: var(--dark, #222);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 159.008%; /* 1.59006rem */
  /* width: 16.875rem; */
  padding-bottom: 1.5rem;
  height: 8rem;
  width: 100%;
  padding: 0 1.3rem 1.5rem 1.3rem;
}
.c_container .text {
  color: var(--dark, #222);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 159.008%; /* 1.59006rem */
  width: 16.875rem;
  padding-bottom: 1.5rem;
  height: 6.8rem;
  overflow: hidden;
}
@media only screen and (max-width: 1024px) {
  .c_container {
    width: 15rem;
    gap: 0.625rem;
  }
  .c_container img,
  .c_container video {
    width: 100%;
    flex-shrink: 0;
    align-self: stretch;
    object-fit: cover;
  }
  .c_container .text {
    font-size: 1rem;
    width: 100%;
    /* padding: 0 1.3rem 1.5rem 1.3rem; */
  }
}
@media only screen and (max-width: 900px) {
  .c_container {
    width: 13rem;
    gap: 0.625rem;
  }
  .c_container img,
  .c_container video {
    width: 100%;
    /* height: 5.4375rem; */
    flex-shrink: 0;
    align-self: stretch;
    object-fit: cover;
  }
}
@media only screen and (max-width: 500px) {
  .c_container {
    width: calc((100vw - 3.125rem));
  }
  .c_container img {
    width: 100%;
    /* height: 8.9375rem; */
    align-self: stretch;
    /* object-fit: cover; */
  }
}
.c_container video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

.c_container video::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

.c_container video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
