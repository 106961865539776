@import url("https://fonts.googleapis.com/css2?family=Segoe+UI&display=swap");

body {
  margin: 0;
}
* {
  box-sizing: border-box;
  margin: 0;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_h_end {
  display: flex;

  justify-content: end;
}

.d_chatty_none {
  display: none;
}

.d_chatty_flex {
  display: flex;
}
.bold {
  font-weight: 700;
}
.normal {
  font-weight: 400;
}
@media only screen and (min-width: 500px) {
  .d_chatty_sm_flex {
    display: flex;
  }
  .d_chatty_sm_none {
    display: none;
  }
}
.sticky_bottom {
  position: sticky;
  bottom: 3.5rem;
}
.sticky_top {
  position: sticky;
  top: 0;
  z-index: 999;
}
@media only screen and (max-width: 500px) {
  .flex-xsm-center {
    display: flex;
    justify-content: center;
  }
}
video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden !important;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
