.sidebar_container {
  display: block;
  position: fixed;
  right: calc((100vw - 90rem) / 2);
}
.sidebar_container .sub_container {
  width: 26.25rem;
}
.btn {
  margin-top: 1.25rem;
  display: flex;
  width: 26.25rem;
  height: 3rem;
  padding: 0.5rem 0.875rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 1.5625rem;
  border: 0.5px solid #a5a5a5;
  margin-left: auto;
  margin-right: auto;
}
.btn .content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.btn .text {
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(
    --l1,
    linear-gradient(91deg, #a04bff 9.06%, #ff4ba1 58.02%, #fff625 117.1%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn svg {
  width: 1.5rem;
  height: 1.5rem;
  /* transform: rotate(90deg); */
}

@media only screen and (max-width: 1441px) {
  .sidebar_container {
    right: 4.3rem;
  }
}

@media only screen and (max-width: 1435px) {
  .sidebar_container {
    /* right: 4.3rem; */
    transform: scale(0.8) translateY(-15%);
  }
}
@media only screen and (max-width: 1100px) {
  .sidebar_container {
    right: 1rem;
    transform: scale(0.7) translateY(-20%);
  }
}
@media only screen and (max-width: 1024px) {
  .sidebar_container {
    display: none;
  }
  .btn {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1441px) {
  .sidebar_container {
    right: 6.25rem;
  }
}
@media only screen and (min-width: 1500px) {
  .sidebar_container {
    right: calc((100vw - 90rem) / 2);
  }
}
