.not_found_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  margin-top: 100px;
  margin-bottom: 100px;
}
.not_found_container svg {
  width: 18.58606rem;
  height: 14.78588rem;
}
.not_found_container .text {
  color: var(--Grey, #868686);
  text-align: center;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 157.023%; /* 2.35531rem */
  padding: 0 1.5rem;
}
.not_found_container .btn {
  display: flex;
  width: 19.75rem;
  height: 3rem;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.5625rem;
  background: #4886ff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.btn .text {
  color: #fff;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4rem; /* 192% */
}
@media only screen and (max-width: 1024px) {
  .not_found_container svg {
    width: 13.9395rem;
    height: 11.08944rem;
  }
  .not_found_container .text {
    color: var(--Grey, #868686);
    text-align: center;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 157.023%; /* 1.96281rem */
  }
  .not_found_container .btn {
    display: flex;
    width: 19.75rem;
    height: 3rem;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1.5625rem;
    background: #4886ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  }
  .btn .text {
    color: #fff;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem; /* 192% */
  }
}
@media only screen and (max-width: 500px) {
}
