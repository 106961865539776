.searchbar_container {
  height: 3.75rem;
  width: 53.75rem;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  .searchbar_container {
    width: 34.375rem;
    height: 3rem;
  }
}
@media only screen and (max-width: 500px) {
  .searchbar_container {
    width: calc((100vw - 3.125rem));
  }
}
