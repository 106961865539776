.container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
}
.trending_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.trending_container .trend {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.trend svg {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.28125rem;
  justify-content: center;
  align-items: center;
  border-radius: 2.34375rem;
  background: #ff3737;
}
.trend .text {
  color: var(--dark, #222);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.trending_container .explore {
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(
    --l1,
    linear-gradient(91deg, #a04bff 9.06%, #ff4ba1 58.02%, #fff625 117.1%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/*  */

.cards_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 19rem);
  row-gap: 1.5rem;
  column-gap: 1rem;
  justify-content: space-between;
}

.cards_container &::after {
  content: "";
  flex: auto;
}
@media only screen and (max-width: 1024px) {
  .container {
    grid-template-columns: repeat(auto-fill, 15rem);
    gap: 1rem;
  }
  .trend svg {
    width: 0.9375rem;
    height: 0.9375rem;
    flex-shrink: 0;
  }
  .trend .text {
    font-size: 1rem;
  }
  .trending_container .explore {
    font-size: 1rem;
  }
  /*  */

  .cards_container {
    gap: 1.25rem;
  }
}

@media only screen and (max-width: 900px) {
  .cards_container {
    grid-template-columns: repeat(auto-fill, 13rem);
  }
}

@media only screen and (max-width: 500px) {
  .cards_container {
    grid-template-columns: repeat(auto-fill, calc(100vw - 3.125rem));
  }
}

@keyframes loading {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.skeleton_card {
  width: 19rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
}

.skeleton_card_image {
  width: 100%;
  height: 8.9375rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

.skeleton_card_content {
  padding: 16px;
  padding-bottom: 24px;
  height: 8.9375rem;
}

.description {
  width: 100%;
  /* height: 6.5rem; */
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  padding-bottom: 3.5rem;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

@media only screen and (max-width: 1024px) {
  .skeleton_card {
    width: 15rem;
    gap: 0.625rem;
  }
}

@media only screen and (max-width: 900px) {
  .skeleton_card {
    width: 13rem;
    gap: 0.625rem;
  }
}

@media only screen and (max-width: 500px) {
  .skeleton_card {
    width: calc((100vw - 3.125rem));
  }
}
