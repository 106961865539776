.container {
  padding: 0 4.3rem;
  box-sizing: border-box;
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 0 1.5rem;
    /* overflow-x: hidden; */
  }
}
/* @media only screen and (min-width: 1200px) {
  .container {
    padding: 0 5rem;
  }
}  */
@media only screen and (min-width: 1441px) {
  .container {
    padding: 0 calc(100vw - 90rem);
  }
}
@media only screen and (min-width: 1441px) {
  .container {
    padding: 0 6.25rem;
  }
}
@media only screen and (min-width: 1500px) {
  .container {
    padding: 0 calc((100vw - 90rem) / 2);
  }
}
/* @media only screen and (max-width: 1440px) {
  .container {
    padding: 0 1.5rem;
  }
} */
