.container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
}
.order_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.title_rating_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.title {
  color: var(--dark, #222);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2.4rem;
  width: 51.25rem;
}
.title span {
  color: var(--Blue, #4886ff);
}
.rating_review_container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.rating_text {
  color: var(--dark, #222);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  /* font-weight: 600; */
  line-height: normal;
}
.reviews_text {
  color: var(--dark, #222);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.detail_img {
  width: 51.25rem;
  height: 24.5625rem;
  border-radius: 1rem;
  object-fit: cover;
}

.para_container {
  width: 51.25rem;
  color: var(--dark, #222);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 2.4rem;
}
.container .bold {
  color: var(--dark, #222);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem; /* 192% */
}
.underline {
  text-decoration: underline;
}

.learn_more {
  width: 51.25rem;
  color: var(--Blue, #4886ff);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4rem; /* 192% */
}

.deal_container .today_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.today_container .image {
  width: 2rem;
  height: 2rem;
}
.today_container .text {
  color: var(--white, #fff);
  font-family: Segoe UI;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.53125rem; /* 126.563% */
  text-transform: uppercase;
}
.deal_container .offer {
  color: #fff;
  text-align: center;
  font-family: Segoe UI;
  font-size: 1.1875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.78125rem; /* 150% */
}
.deal_container .offer_price_container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.offer_price_container .price1 {
  color: #fff;
  text-align: center;
  font-family: Segoe UI;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.40625rem; /* 93.75% */
  text-decoration-line: strikethrough;
}
.offer_price_container .price2 {
  color: var(--white, #fff);
  text-align: center;
  font-family: Segoe UI;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 60% */
}
.deal_container .get_deal_btn {
  display: flex;
  width: 9.875rem;
  height: 3rem;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.5625rem;
  background: var(--white, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}
.get_deal_btn .content {
  display: flex;
  align-items: flex-start;
  gap: 0.5625rem;
}
.get_deal_btn .content .text {
  color: var(--Blue, #4886ff);
  font-family: Segoe UI;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 159.008%; /* 1.59006rem */
}
.get_deal_btn .content .image {
  width: 1.5rem;
  height: 1.5rem;
}
/*  */
.learn_more_btn {
  display: flex;
  width: 51.25rem;
  /* height: 3rem;
   */
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.5625rem;
  background: #4886ff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.learn_more_btn .text {
  text-align: center;
  color: #fff;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5rem; /* 192% */
}
.learn_more_btn svg {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  margin-top: 0.3rem;
}

@media only screen and (max-width: 1435px) {
  .order_container {
    /* flex-direction: column-reverse; */
  }
  .title {
    width: 100%;
  }

  /*  */
  .detail_img {
    width: 100%;
    /* height: 14.875rem; */
    align-self: stretch;
  }

  .para_container {
    width: 100%;
    font-size: 1rem;
  }
  .container .bold {
    color: var(--dark, #222);
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem; /* 192% */
  }
  .underline {
    text-decoration: underline;
  }

  .learn_more {
    width: 100%;
    color: var(--Blue, #4886ff);
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem; /* 192% */
  }

  .learn_more_btn {
    display: flex;
    width: 100%;
    /* height: 3rem; */
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1.5625rem;
    background: #4886ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  }
  .learn_more_btn .text {
    color: #fff;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem; /* 192% */
  }
  .learn_more_btn svg {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .order_container {
    /* flex-direction: column-reverse; */
  }
  .title {
    width: 100%;
  }

  /*  */
  .detail_img {
    width: 100%;
    /* height: 14.875rem; */
    align-self: stretch;
  }

  .para_container {
    width: 100%;
    font-size: 1rem;
  }
  .container .bold {
    color: var(--dark, #222);
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem; /* 192% */
  }
  .underline {
    text-decoration: underline;
  }

  .learn_more {
    width: 100%;
    color: var(--Blue, #4886ff);
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem; /* 192% */
  }

  .learn_more_btn {
    display: flex;
    width: 100%;
    /* height: 3rem; */
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1.5625rem;
    background: #4886ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  }
  .learn_more_btn .text {
    color: #fff;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem; /* 192% */
  }
  .learn_more_btn svg {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
  }
}
@media only screen and (max-width: 500px) {
  .title {
    width: 100%;
    font-size: 1.25rem;
  }
  .rating_text,
  .reviews_text {
    font-size: 1rem;
  }
  /*  */
  .detail_img {
    width: 100%;
    /* height: 14.875rem; */
    align-self: stretch;
  }

  .para_container {
    width: 100%;
    font-size: 1rem;
  }

  .learn_more {
    width: 100%;
    color: var(--Blue, #4886ff);
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem; /* 192% */
    overflow-x: hidden;
  }
  .learn_more_btn .text {
    font-size: 1rem;
  }
  /*  */
  .learn_more_btn {
    display: flex;
    width: 100%;
    /* height: 3rem; */
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1.5625rem;
    background: #4886ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  }
  .learn_more_btn .text {
    color: #fff;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 192% */
    overflow: hidden;
  }
  .learn_more_btn svg {
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    margin-top: 0;
  }
}

.title_skeleton {
  color: var(--dark, #222);
  line-height: 2.4rem;
  width: 51.25rem;
  height: 76px;
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

.rating_review_container_skeleton {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 24px;
  width: 15rem;
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
.para_container_skeleton {
  width: 51.25rem;
  height: 250px;
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

.learn_more_btn_skeleton {
  display: flex;
  width: 51.25rem;
  height: 3rem;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.5625rem;
  background: #4886ff;
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.image_skeleton {
  width: 51.25rem;
  height: 300px;
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
