.svgIconSmall {
  display: flex;
  flex-direction: row;
}
.svgIconBig {
  display: none;
}

@media (min-width: 576) {
  .svgIconBig {
    display: flex;
    flex-direction: row;
  }
  .svgIconSmall {
    display: none;
  }
}
