.lottieLoader {
  width: 75px;
  height: 75px;
}
@media only screen and (min-width: 576px) {
  .lottieLoader {
    width: 75px;
    height: 75px;
  }
}
@media only screen and (min-width: 1024px) {
  .lottieLoader {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (min-width: 1024px) {
  .lottieLoader {
    width: 150px;
    height: 150px;
  }
}
