.searchContainer {
  position: relative;
  display: flex;
  width: 12rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.text {
  position: absolute;
  /* top: 0; */
  left: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
  color: var(--Grey, #868686);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.text.visible {
  opacity: 1;
}
@media only screen and (max-width: 1024px) {
  .text {
    font-size: 1rem;
  }
}
