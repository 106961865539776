.search_header {
  /* Styles for .container .search_header class */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  /* margin: 4.2rem 18.5rem 0rem 18.5rem; */
  width: 53.75rem;
  text-align: center;
}

.head {
  /* Styles for .container .head class */
  color: var(--dark, #222);
  text-align: center;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 157.023%; /* 3.14044rem */
  display: block;
}

.head span {
  background: var(
    --l1,
    linear-gradient(91deg, #a04bff 9.06%, #ff4ba1 58.02%, #fff625 117.1%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 157.023%;
}
.subhead {
  /* Styles for .container .subhead class */
  color: var(--Grey, #868686);
  text-align: center;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 157.023%; /* 1.57025rem */
}
@media only screen and (max-width: 1024px) {
  .search_header {
    width: 41.5rem;
  }

  .head {
    font-size: 1.5rem;
  }
  .head span {
    font-size: 1.5rem;
  }
  .subhead {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 500px) {
  .search_header {
    width: calc((100vw - 3.125rem));
    /* width: 20.3125rem; */
    gap: 1.5rem;
  }

  .head {
    font-size: 1.125rem;
  }

  .head span {
    font-size: 1.125rem;
  }
  .subhead {
    font-size: 0.875rem;
  }
}
